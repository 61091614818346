import { ISvgIconProps } from '.';

export const CartIcon = ({
	className = '',
	viewBox = '0 0 30 30',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			className={className}
			ref={refComponent}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.7187 5H19.2813C21.5992 5 23.5775 6.64926 23.9586 8.89947L25.9343 20.5661C26.416 23.4106 24.1872 26 21.257 26H8.74299C5.81283 26 3.58398 23.4106 4.06569 20.5661L6.04145 8.89947C6.42252 6.64926 8.40076 5 10.7187 5ZM15 14.0417C17.4552 14.0417 19.4455 12.0829 19.4455 9.66667V8.5C19.4455 8.01675 19.0474 7.625 18.5564 7.625C18.0653 7.625 17.6673 8.01675 17.6673 8.5V9.66667C17.6673 11.1164 16.4731 12.2917 15 12.2917C13.5269 12.2917 12.3327 11.1164 12.3327 9.66667V8.5C12.3327 8.01675 11.9347 7.625 11.4436 7.625C10.9526 7.625 10.5545 8.01675 10.5545 8.5V9.66667C10.5545 12.0829 12.5448 14.0417 15 14.0417Z"
				fill="currentColor"
			/>
		</svg>
	);
};
