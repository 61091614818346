import { Helmet } from 'react-helmet';
import { IMetaPage } from '../../@types';
import { Footer } from '../footer/footer.component';
import { Header } from '../header/header.component';
import { MenuDropDownContextProvider } from '../menu-dropdown/menu-dropdown.context';

export const PageLayout = ({
	children,
	positionHeader,
	meta,
}: {
	children: JSX.Element;
	positionHeader?: 'absolute' | 'fixed' | 'relative';
	meta?: IMetaPage;
}) => {
	return (
		<MenuDropDownContextProvider>
			<Helmet>
				<title>{meta?.title || 'Page title'}</title>
				<meta name="description" content={meta?.title || 'Page title'} />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="keywords" content={meta?.keywords || 'Page keywords'} />
				<link rel="icon" href="/favicon.svg" />
				<meta name="robots" content={meta?.robots || 'all'} />
			</Helmet>
			<Header position={positionHeader} scrollHide />
			{children}
			<Footer />
		</MenuDropDownContextProvider>
	);
};
