import { Field, Formik } from 'formik';
import { Suspense, lazy, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryData } from 'react-phone-input-2';
import { Button } from '../../ui/buttons/button/button.component';
import { useAuth } from '../../../application/application.auth.context';
import { PHONE_MASK_PREFIX } from '../../../const/regexp.const';
import { apiServices } from '../../../services/api';
import cl from './form-edit-profile.module.scss';
import { InputField, InputMaskField } from '../../ui/input';
import { InputPhoneField } from '../../ui/input-phone';
import {
	ProfileEditFormData,
	profileEditFormSchema,
} from './form-edit-profile.schema';

const ModalCodeEmail = lazy(
	() => import('../../modals/modal-code/modal-code.component'),
);

const Loading = () => {
	const { t } = useTranslation();
	return (
		<div className={cl.form}>
			<div className={cl.form__input__loading + ' skeleton'}></div>
			<div className={cl.form__input__loading + ' skeleton'}></div>
			<div className={cl.form__input__loading + ' skeleton'}></div>
			<Button type="button" className={cl.form__submit} disabled>
				{t('form.btn.change')}
			</Button>
		</div>
	);
};

const FormEditProfile = () => {
	const [emails, setEmails] = useState<{ old: string; new: string } | null>(
		null,
	);
	const [loading, setLoading] = useState(false);
	const errorRef = useRef<HTMLDivElement | null>(null);
	const { isAuth, user, updateAuthData } = useAuth();
	const { t } = useTranslation();
	const [country, setCountry] = useState<CountryData>({
		name: 'United Arab Emirates',
		dialCode: '971',
		countryCode: 'ae',
		format: '+... ... ... ...',
	});

	const onSubmit = async (values: ProfileEditFormData) => {
		if (isAuth && user) {
			if (errorRef && errorRef.current) {
				errorRef.current.style.display = 'none';
			}
			setLoading(true);
			apiServices
				.updateUserInfo({
					phone: '+' + values.phone.replace(/\D/g, ''),
					first_name: values.name,
					email: values.email,
				})
				.then((res) => {
					if (res.email !== values.email) {
						setEmails({ old: res.email, new: values.email });
					}
					updateAuthData({ isAuth: true, data: { ...user, ...res } });
				})
				.catch((error) => {
					if (errorRef && errorRef.current) {
						errorRef.current.textContent = error.msg;
						errorRef.current.style.display = 'block';
					}
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const updateEmail = async (code: string): Promise<void> => {
		if (user && isAuth && emails) {
			try {
				await apiServices.updateUserEmailInfo({
					user: user.email,
					token: code,
					new_email: emails?.new,
				});
				updateAuthData({
					data: { ...user, email: emails.new },
					isAuth: true,
				});
				setEmails(null);
			} catch (error) {
				return Promise.reject(JSON.stringify(error));
			}
		}
	};

	const handleSetCountry = (countryData: CountryData) => {
		setCountry(countryData);
	};

	return (
		<div className={cl.form__wrap} key="form-edit-profile">
			<h4 className={cl.form__title}>{t('form.profile.info.title')}</h4>
			{!isAuth || user === null ? (
				<Loading key="form-profile-loading" />
			) : (
				<Formik
					key="form-profile"
					onSubmit={onSubmit}
					initialValues={{
						email: user.email,
						phone: user.phone,
						name: user.first_name,
						country: country,
					}}
					validationSchema={profileEditFormSchema}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						setSubmitting,
						isSubmitting,
						setFieldValue,
					}) => (
						<form
							onSubmit={handleSubmit}
							autoComplete="off"
							className={cl.form}
						>
							<InputField
								label={t('form.label.name')}
								name="name"
								error={
									touched.name && errors.name
										? t(errors.name as string)
										: undefined
								}
								value={values.name}
								onChange={handleChange}
								onBlur={handleBlur}
								onFocus={() => setSubmitting(false)}
								id={'edit-profile-id-name'}
								classes={{
									input: cl.input,
								}}
							/>
							<InputPhoneField
								field={{
									name: 'phone',
									value: values.phone,
									onChange: handleChange,
									onBlur: handleBlur,
								}}
								form={{
									touched: touched as Record<string, boolean>,
									errors: errors as Record<string, string>,
									setFieldValue,
								}}
								country={country}
								autofocus={false}
								id={'edit-profile-id-phone'}
								label={t('form.label.phone')}
								name="phone"
								error={
									touched.phone && errors.phone
										? t(errors.phone as string)
										: undefined
								}
								value={values.phone}
								onFocus={() => {
									setSubmitting(false);
								}}
								onUpdateCountry={handleSetCountry}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<InputField
								label={t('form.label.email')}
								name="email"
								error={
									touched.email && errors.email
										? t(errors.email as string)
										: undefined
								}
								value={values.email}
								onChange={handleChange}
								onBlur={handleBlur}
								onFocus={() => setSubmitting(false)}
								id={'edit-profile-id-email'}
								classes={{
									input: cl.input,
								}}
							/>
							<Button
								type="submit"
								className={cl.form__submit}
								disabled={
									loading ||
									isSubmitting ||
									!!(errors.name && touched.name) ||
									!!(errors.email && touched.email) ||
									!!(errors.phone && touched.phone)
								}
							>
								{t('label.save')}
							</Button>
							<div
								className={cl.form_error}
								ref={errorRef}
								style={{ display: 'none' }}
							></div>
						</form>
					)}
				</Formik>
			)}
			<Suspense fallback={<div />}>
				<ModalCodeEmail
					title={t('form.code.profile.title')}
					open={!!emails}
					onClose={() => setEmails(null)}
					onSend={updateEmail}
				/>
			</Suspense>
		</div>
	);
};
export default FormEditProfile;
