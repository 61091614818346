import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format, parse } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Participate, RoutsPath } from '../../@types';
import { AsideNavigation } from '../../components/aside/aside-profile/aside-profile.component';
import ContentLayout from '../../components/layout/content-layout/content-layout.component';
import { PageLayout } from '../../components/layout/page.layout';
import { Breadcrumbs } from '../../components/ui/breadcrumbs/breadcrumbs.component';
import { ButtonGroup } from '../../components/ui/button-group/button-group.component';
import { CardRecord } from 'src/components/card/card-record/card-record.component';
import { apiServices } from '../../services/api';
import cl from './index.module.scss';

type Record = 'first' | 'last';

const useRecords = ({
	limit = 10,
	offset = 0,
	isActive = true,
}: {
	limit: number;
	offset: number;
	isActive: boolean;
}) => {
	return useQuery({
		queryKey: [limit, offset, isActive, 'orders'],
		queryFn: async () => {
			return await apiServices.getRecords({ limit, offset, isActive });
		},
	});
};

// Хук для выполнения события (отправка запроса по клику)
const useActionEvent = () => {
	return useMutation({
		mutationFn: async ({ id, type }: { id: string; type: Participate }) => {
			return await apiServices.updateScheduleParticipate(id, type);
		},
	});
};

const ProfileRecordsPage = () => {
	const [record, setRecord] = useState<Record>('first');
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { status } = useParams<{ status: 'active' | 'completed' }>();

	const onChangeRecord = (e: React.MouseEvent<HTMLButtonElement>) => {
		const target = e.currentTarget;
		const data = target.dataset.value || 'first';
		setRecord(data as Record);
		navigate(`/profile/events/${record === 'last' ? 'active' : 'completed'}`);
	};

	useEffect(() => {
		if (status === 'completed') {
			setRecord('last');
		} else if (status === 'active') {
			setRecord('first');
		} else {
			navigate('/404');
		}
	}, [status]);

	const { data, isLoading } = useRecords({
		limit: 10,
		offset: 0,
		isActive: record === 'first',
	});

	const generateDuration = (dateStart: string, timeEnd: string) => {
		const start = parse(dateStart, 'dd.MM.yyyy HH:mm:ss', new Date());
		const end = parse(timeEnd, 'HH:mm:ss', new Date());
		return format(start, 'HH:mm') + ' - ' + format(end, 'HH:mm');
	};

	// Инициализация мутации для отправки запросов
	const { mutate: triggerActionEvent } = useActionEvent();

	const handleClickEvent = (event: any) => {
		triggerActionEvent(
			{
				id: event.id,
				type:
					event.status === 'cancelled' || event.status === 'pay_incomplete'
						? 'participate'
						: 'cancel_participate',
			},
			{
				onSuccess: (response) => {
					console.log(response);
					if (response.payment_link) {
						window.location.href = response.payment_link;
					}
					queryClient.invalidateQueries({
						queryKey: [10, 0, record === 'first', 'orders'],
					});
				},
			},
		);
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<PageLayout positionHeader="fixed">
			<div className={cl.page}>
				<div className={'header-offset'} />
				<div className="container">
					<div className={'box20'}></div>
					<Breadcrumbs
						items={[
							{
								name: t('page.profile'),
								translate: true,
								href: RoutsPath.profile,
							},
							{ name: 'page.profile.events', translate: true },
						]}
					/>
					<h1 className="title-section-small padding-bottom_40">
						{t('page.profile.events')}
					</h1>
					<ContentLayout aside={<AsideNavigation />} small mobileTabs>
						<>
							<div className={cl.tab}>
								<ButtonGroup
									active={record}
									classes={{
										button: cl.tab__item,
										root: cl.tab__item,
									}}
									buttons={[
										{
											label: t('filter.active'),
											onClick: onChangeRecord,
											data: 'first',
										},
										{
											label: t('filter.closed'),
											onClick: onChangeRecord,
											data: 'last',
										},
									]}
								/>
							</div>

							{!isLoading && data && data.results.length === 0 && (
								<div className={cl.list__empty}>
									{t('profile.events.empty')}
								</div>
							)}

							{isLoading && (
								<ul className={cl.list}>
									<li className={cl.list__item}>
										<div className={cl.list__item__loading + ' skeleton'}></div>
									</li>
									<li className={cl.list__item}>
										<div className={cl.list__item__loading + ' skeleton'}></div>
									</li>
									<li className={cl.list__item}>
										<div className={cl.list__item__loading + ' skeleton'}></div>
									</li>
								</ul>
							)}

							{data && data.results.length > 0 && !isLoading && (
								<ul className={cl.list}>
									{data.results.map((item) => (
										<li className={cl.list__item} key={item.id}>
											<CardRecord
												data={{
													title: item.title,
													duration: generateDuration(
														item.date,
														item.time_finish,
													),
													address: item.event_location.address,
													date: item.date,
													currentMember: item.users_cnt,
													maxMember: item.max_users,
													id: item.id,
													type: item.type,
													status: item.status,
												}}
												onClick={(_, data) => handleClickEvent(data)}
											></CardRecord>
										</li>
									))}
								</ul>
							)}
						</>
					</ContentLayout>
				</div>
			</div>
		</PageLayout>
	);
};

export default ProfileRecordsPage;
