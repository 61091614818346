import { useRef } from 'react';
import { Navigation, Parallax } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ISwiper } from '../../@types';
import { Preview } from 'src/@types';
import { ArrowRightTwoIcon } from '../ui/icons';
import cl from './slider-ellipse.module.scss';

const BREAKPOINTS = {
	0: {
		slidesPerView: 1.2,
		spaceBetween: 30,
		centeredSlides: true,
	},
	1280: {
		slidesPerView: 1.2,
		spaceBetween: 24,
		centeredSlides: true,
	},
};

export const SliderSticky = ({ slides }: { slides: Preview[] }) => {
	const refSwiper = useRef<ISwiper | null>(null);

	return (
		<div className={cl.section}>
			<div
				className={
					cl.navigation__btn +
					' ' +
					cl.navigation__btn__prev +
					' swiper-elipse-button-prev'
				}
				role="button"
			>
				<ArrowRightTwoIcon className={cl.navigation__btn__icon} />
			</div>

			<div
				className={
					cl.navigation__btn +
					' ' +
					cl.navigation__btn__next +
					' swiper-elipse-button-next'
				}
				role="button"
			>
				<ArrowRightTwoIcon className={cl.navigation__btn__icon} />
			</div>
			<Swiper
				breakpoints={BREAKPOINTS}
				init={false}
				loop={true}
				speed={800}
				onSwiper={(sw: unknown) => (refSwiper.current = sw as ISwiper)}
				modules={[Navigation, Parallax]}
				parallax={true}
				className={cl.swiper + ' swiper-slice'}
				navigation={{
					nextEl: '.swiper-elipse-button-next',
					prevEl: '.swiper-elipse-button-prev',
					disabledClass: 'swiper-elipse-button-disabled',
				}}
				onSlideChangeTransitionStart={(sw: any) => {
					const img = sw.visibleSlides[1].querySelector('.img-sl');
					img?.classList.remove('img-sl-left');
					img?.classList.remove('img-sl-right');

					if (sw.activeIndex > sw.previousIndex) {
						img?.classList.add('img-sl-left');
					} else {
						img?.classList.add('img-sl-right');
					}
				}}
			>
				{slides.map((slide, index) => (
					<SwiperSlide
						className={cl.swiper__slide + ' index-' + index}
						key={index}
					>
						<picture className={cl.swiper__slide__img}>
							{slide.map((itemImg) => (
								<source
									type={'image/' + itemImg.type}
									srcSet={itemImg.uri}
									key={itemImg.type}
								/>
							))}
							<img
								src={slide[1].uri}
								className={cl.swiper__slide__img + ' img-sl'}
								alt=""
								loading="lazy"
							/>
						</picture>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};
