import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Langs, RoutsPath } from '../../@types';
import { SwitcherLang } from '../switcher-lang/switcher-lang.component';
import { Button } from '../ui/buttons/button/button.component';
import { useAuth } from '../../application/application.auth.context';
import { MenuDropDownContext } from '../menu-dropdown/menu-dropdown.context';
import { ModalAuthContext } from '../modals/modal-auth/modal-auth.context';
import { useHeaderVisible } from '../../hooks/useHeaderVisible';
import { useWindowSize } from '../../hooks/useWindowSize';
import {
	BurgerIcon,
	CartIcon,
	CloseIcon,
	LogoInToIcon,
	ProfileIcon,
} from '../ui/icons';
import { createLangHref } from '../../utils/href.utils';
import './header.scss';

export const Header = ({
	position,
	scrollHide = false,
}: {
	position?: 'absolute' | 'fixed' | 'relative';
	scrollHide?: boolean;
}) => {
	const { t, i18n } = useTranslation();
	const { isAuth } = useAuth();
	const { visibleType } = useHeaderVisible();
	const { onToggleModal, open } = useContext(MenuDropDownContext);
	const { toggleOpen, open: openAuth } = useContext(ModalAuthContext);
	const { isMobile, width = window.innerWidth } = useWindowSize();
	const navigation = useNavigate();
	const singup = () =>
		navigation(createLangHref(i18n.language, RoutsPath.schedule));
	const gocart = () =>
		navigation(createLangHref(i18n.language, RoutsPath.cart));
	const goHome = () => {
		const lang = i18n.language;
		navigation(lang === Langs.en ? RoutsPath.home : '/' + Langs.ru);
	};
	const onToggleMenu = () => onToggleModal(!open);
	const position_class = position !== undefined ? 'header_' + position : '';

	const toggleOpenAuth = () => {
		toggleOpen(!openAuth);
	};

	const checkProfile = () => {
		if (isAuth === false) {
			toggleOpenAuth();
		} else {
			navigation(createLangHref(i18n.language, RoutsPath.profile));
		}
	};

	return (
		<header
			className={`header ${position_class} ${
				position === 'fixed' && scrollHide ? visibleType : ''
			}`}
		>
			<div className="container">
				<div className="header__row">
					<div className="header__logo" onClick={goHome}>
						<svg
							viewBox="0 0 188 57"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M4.08905 7.76023C2.96852 7.76023 2.00933 7.38012 1.2056 6.61988C0.401865 5.86257 0 4.94737 0 3.88012C0 2.81287 0.401865 1.90058 1.2056 1.14035C2.00639 0.380117 2.96852 0 4.08905 0C5.20958 0 6.16878 0.380117 6.97251 1.14035C7.77624 1.90058 8.1781 2.81287 8.1781 3.88012C8.1781 4.94737 7.77624 5.85965 6.97251 6.61988C6.16878 7.38012 5.20958 7.76023 4.08905 7.76023ZM1.16746 51.2222V13.9708H6.91091V51.2251H1.16746V51.2222Z"
								fill="currentColor"
							/>
							<path
								d="M23.9212 28.8128V51.2222H18.1777V13.9707H23.7246V19.7924H24.2116C25.0886 17.9006 26.4174 16.3772 28.2009 15.2193C29.9844 14.0643 32.29 13.4854 35.1118 13.4854C37.6433 13.4854 39.8579 14 41.7529 15.0263C43.6507 16.0526 45.1262 17.6052 46.1822 19.6842C47.2352 21.7631 47.7632 24.3859 47.7632 27.5555V51.228H42.0198V27.9444C42.0198 25.0175 41.2571 22.7339 39.7318 21.0935C38.2065 19.4532 36.115 18.6316 33.4545 18.6316C31.6212 18.6316 29.9873 19.0292 28.5529 19.8187C27.1185 20.6111 25.9862 21.7661 25.159 23.2865C24.3318 24.807 23.9182 26.6491 23.9182 28.8158L23.9212 28.8128Z"
								fill="currentColor"
							/>
							<path
								d="M99.4633 13.9708V18.8217H80.0469V13.9708H99.4633ZM85.7074 5.04395H91.465V40.5498C91.465 42.1668 91.7062 43.3744 92.1855 44.1755C92.6648 44.9767 93.2823 45.5089 94.038 45.7749C94.7937 46.041 95.5936 46.1755 96.4404 46.1755C97.0756 46.1755 97.5931 46.1404 98.0019 46.0673C98.4077 45.9942 98.7341 45.9328 98.9781 45.8861L100.148 51.0264C99.7574 51.1726 99.2134 51.3217 98.5135 51.4738C97.8137 51.6258 96.9286 51.7048 95.8553 51.7048C94.2291 51.7048 92.6383 51.3568 91.0857 50.6609C89.5331 49.965 88.2481 48.9065 87.2306 47.4825C86.2132 46.0586 85.7074 44.2662 85.7074 42.0995V5.04395Z"
								fill="currentColor"
							/>
							<path
								d="M122.975 52.0002C119.593 52.0002 116.629 51.1991 114.083 49.5996C111.539 48.0002 109.554 45.7605 108.131 42.8803C106.708 40.0031 105.996 36.6406 105.996 32.7897C105.996 28.9388 106.708 25.5207 108.131 22.6289C109.554 19.7341 111.536 17.4885 114.083 15.8862C116.626 14.2838 119.59 13.4856 122.975 13.4856C126.359 13.4856 129.321 14.2868 131.864 15.8862C134.408 17.4856 136.393 19.7341 137.816 22.6289C139.239 25.5236 139.951 28.9096 139.951 32.7897C139.951 36.6698 139.239 40.0002 137.816 42.8803C136.393 45.7575 134.408 47.9973 131.864 49.5996C129.321 51.202 126.356 52.0002 122.975 52.0002ZM122.975 46.8569C125.545 46.8569 127.659 46.202 129.318 44.892C130.976 43.5821 132.205 41.8599 133.002 39.7254C133.799 37.5909 134.196 35.278 134.196 32.7897C134.196 30.3014 133.799 27.9797 133.002 25.8277C132.205 23.6786 130.979 21.9388 129.318 20.6143C127.659 19.2897 125.545 18.6259 122.975 18.6259C120.405 18.6259 118.291 19.2897 116.632 20.6143C114.974 21.9388 113.744 23.6786 112.948 25.8277C112.151 27.9797 111.754 30.2985 111.754 32.7897C111.754 35.2809 112.151 37.5909 112.948 39.7254C113.744 41.8599 114.974 43.5821 116.632 44.892C118.291 46.202 120.405 46.8569 122.975 46.8569Z"
								fill="currentColor"
							/>
						</svg>
					</div>
					{!isMobile && (
						<div className="header__navigation">
							<nav className="header__navigation__links">
								<Link
									to={createLangHref(i18n.language, RoutsPath.about)}
									className="header__link"
								>
									{t('page.about')}
								</Link>
								<Link
									to={createLangHref(i18n.language, RoutsPath.practice)}
									className="header__link"
								>
									{t('page.practice')}
								</Link>
								<Link
									to={createLangHref(i18n.language, RoutsPath.schedule)}
									className="header__link"
								>
									{t('page.schedule')}
								</Link>
								<Link to={'#contacts'} className="header__link">
									{t('menu.contacts')}
								</Link>
							</nav>

							<Button
								className={'header__signup'}
								theme="white-light"
								onClick={singup}
							>
								{t('btn.book')}
							</Button>
						</div>
					)}

					<div className={'header__actions ' + (open ? 'active' : '')}>
						{width >= 768 && (
							<>
								<SwitcherLang classes={{ title: 'header__lang__title' }} />
								<div
									role="button"
									className="header__actions__item"
									onClick={gocart}
								>
									<CartIcon />
								</div>
								<div
									role="button"
									className="header__actions__item"
									onClick={checkProfile}
								>
									<ProfileIcon />
								</div>
							</>
						)}

						<div
							role="button"
							className="header__actions__item"
							onClick={onToggleMenu}
						>
							<BurgerIcon
								className={`header__menu__open ${open ? 'hide' : ''}`}
							/>
							<CloseIcon
								className={`header__menu__close ${open ? 'show' : ''}`}
							/>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};
