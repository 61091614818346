export enum RoutsPath {
	home = '/',
	not_found = '*',
	not_found_base = '/404',
	meditation = '/meditation',
	events = '/events',
	directions = '/directions',
	schedule = '/schedule',
	profile = '/profile',
	orders_profile = '/profile/orders',
	events_profile = '/profile/events/:status',
	audio = '/audio',
	catalog = '/catalog',
	catalog_slug = '/catalog/:slug',
	cart = '/cart',
	order = '/order',
	practice = '/practice',
	сompany_retreats = '/company-retreats',
	learning_to_play_instruments = '/learning-to-play-instruments',
	about = '/#about',
	contacts = '/#contacts',
	buyers = '/buyers',
}
