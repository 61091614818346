import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CardPractice } from 'src/components/card/card-practice/card-practice.component';
import { PageLayout } from 'src/components/layout/page.layout';
import { Breadcrumbs } from 'src/components/ui/breadcrumbs/breadcrumbs.component';
import cl from './index.module.scss';
import { usePracticeMock } from '../practice-slug/mock/data.mock';

const PracticesPage = () => {
	const { t, i18n } = useTranslation();
	const practices = usePracticeMock();
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<PageLayout positionHeader="fixed">
			<div className={cl.page}>
				<div className={'header-offset'}></div>
				<div className="container">
					<Breadcrumbs
						items={[{ name: t('page.practices'), translate: true }]}
					/>
					<h1 className="title-section-small padding-bottom_40">
						{t('page.practices')}
					</h1>
					<div className={cl.grid}>
						<div className={cl.col}>
							<CardPractice {...practices['sound-healing'].post} />
							<CardPractice
								{...practices['breathwork'].post}
								shift={i18n.language === 'ru'}
							/>
						</div>
						<div className={cl.col}>
							<CardPractice {...practices['guided-meditations'].post} />
						</div>
						<div className={cl.col + ' ' + cl.equal}>
							<CardPractice {...practices['tea-ceremonies'].post} />
							<CardPractice {...practices['movement'].post} />
						</div>
					</div>
				</div>
			</div>
		</PageLayout>
	);
};

export default PracticesPage;
