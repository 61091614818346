import { LegacyRef, forwardRef, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Langs, RoutsPath } from '../../@types';
import { Socials } from '../socials/socials.component';
import { Terabit } from '../ui/terabit/terabit.component';
import { useApplication } from '../../application/application.context';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { LogoInToIcon } from '../ui/icons';
import { createLangHref } from '../../utils/href.utils';
import './footer.scss';

export const Footer = forwardRef(
	(_, ref: LegacyRef<HTMLElement> | undefined) => {
		const refFooter = useRef<HTMLElement | null>(null);
		const { hash } = useLocation();
		const { t, i18n } = useTranslation();
		const { store_options } = useApplication();
		const options = store_options.state.options;
		const refMount = useRef<boolean>(false);
		const refTimerId = useRef<NodeJS.Timeout | null>(null);
		const { width = window.innerWidth } = useWindowSize();
		const navigation = useNavigate();
		const goHome = () => {
			const lang = i18n.language;
			navigation(
				lang === Langs.en ? RoutsPath.home : RoutsPath.home + '/' + Langs.ru,
			);
		};

		useEffect(() => {
			refMount.current = true;
			return () => {
				refTimerId.current && clearTimeout(refTimerId.current);
			};
		}, []);

		useEffect(() => {
			refTimerId.current = setTimeout(() => {
				if (refFooter && refFooter.current && hash === '#contacts') {
					refFooter.current.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
						inline: 'center',
					});
				}
			}, 300);
		}, [hash, refMount.current]);

		const sublinks = [
			// <li key={'sub-1'}>
			// 	<Link
			// 		to={createLangHref(i18n.language, RoutsPath.catalog)}
			// 		className="footer__link"
			// 	>
			// 		{t('menu.shop')}
			// 	</Link>
			// </li>,
			// <li key={'sub-2'}>
			// 	<Link
			// 		to={createLangHref(i18n.language, RoutsPath.audio)}
			// 		className="footer__link"
			// 	>
			// 		{t('menu.audio')}
			// 	</Link>
			// </li>,
			<li key={'sub-3'}>
				<Link
					to={createLangHref(i18n.language, RoutsPath.buyers)}
					className="footer__link"
				>
					{t('menu.buyers')}
				</Link>
			</li>,
		];

		return (
			<footer ref={ref || refFooter} className="footer scroll-contacts">
				<div className="footer__top">
					<div className="container">
						<div className="row jcsb aic footer__row">
							<p className="footer__help__text">{t('footer.help')}</p>

							<div className="footer__help__socials" data-lang={i18n.language}>
								<Socials
									links={[
										'https://t.me/into_meditation',
										'https://wa.me/971568463239',
										'',
									]}
									insta={false}
									className="footer__socials"
									big={false}
								/>
								<p>{t('footer.message')}</p>
							</div>

							<p className="footer__help" data-lang={i18n.language}>
								{t('footer.message.info')}
							</p>
						</div>
					</div>
				</div>
				<div className="footer__bottom">
					<div className="container">
						<div className="footer__bottom__row">
							<div className="footer__col__logo footer__col">
								<div className="footer__logo" onClick={goHome}>
									<LogoInToIcon />
								</div>
								<Socials
									links={[
										'https://t.me/in_to_community',
										'https://api.whatsapp.com/send/?phone=971568463239&text&type=phone_number&app_absent=0',
										'https://www.instagram.com/vlada.sharomova/',
									]}
									className="footer__socials__mobile footer__socials"
								/>
								<span className="footer__cookie">{t('link.policy')}</span>
							</div>
							<div className="footer__col__alaya footer__col">
								<div className="footer__title">INTO</div>

								<div className="row footer__row__link">
									<ul className="footer__list">
										<li>
											<Link
												to={createLangHref(i18n.language, RoutsPath.schedule)}
												className="footer__link"
											>
												{t('menu.book_a_session')}
											</Link>
										</li>
										<li>
											<Link
												to={createLangHref(i18n.language, RoutsPath.practice)}
												className="footer__link"
											>
												{t('page.practice')}
											</Link>
										</li>
										{/* <li>
											<Link
												to={createLangHref(
													i18n.language,
													RoutsPath.learning_to_play_instruments,
												)}
												className="footer__link"
											>
												{t('menu.training')}
											</Link>
										</li>
										<li>
											<Link
												to={createLangHref(
													i18n.language,
													RoutsPath.сompany_retreats,
												)}
												className="footer__link"
											>
												{t('menu.for_companies')}
											</Link>
										</li> */}
										{width < 768 && sublinks}
									</ul>
									{width >= 768 && <ul className="footer__list">{sublinks}</ul>}
								</div>
							</div>
							<div className="footer__col__direction footer__col">
								<div className="footer__title">{t('page.practice')}</div>
								<div className="row footer__row__link">
									<ul className="footer__list">
										<li>
											<Link
												to={createLangHref(
													i18n.language,
													RoutsPath.practice + '/sound-healing',
												)}
												className="footer__link"
											>
												{t('practice.sound-healing.banner.title')}
											</Link>
										</li>
										<li>
											<Link
												to={createLangHref(
													i18n.language,
													RoutsPath.practice + '/breathwork',
												)}
												className="footer__link"
											>
												{t('practice.breathwork.banner.title')}
											</Link>
										</li>
										<li>
											<Link
												to={createLangHref(
													i18n.language,
													RoutsPath.practice + '/guided-meditations',
												)}
												className="footer__link"
											>
												{t('practice.guided-meditations.banner.title')}
											</Link>
										</li>
										<li>
											<Link
												to={createLangHref(
													i18n.language,
													RoutsPath.practice + '/movement',
												)}
												className="footer__link"
											>
												{t('practice.one.banner.title')}
											</Link>
										</li>
										<li>
											<Link
												to={createLangHref(
													i18n.language,
													RoutsPath.practice + '/tea-ceremonies',
												)}
												className="footer__link"
											>
												{t('practice.tea-ceremonies.banner.title')}
											</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className="footer__col__contacts footer__col">
								<div className="footer__title">{t('menu.contacts')}</div>
								<ul className="footer__list contact">
									<li>
										{options ? (
											<a
												href={`tel:${options.phone.replaceAll(' ', '')}`}
												className="footer__link"
											>
												{options.phone}
											</a>
										) : (
											<div className="footer__link__loading skeleton" />
										)}
									</li>
									<li>
										{options ? (
											<a href={`tel:${options.email}`} className="footer__link">
												{options.email}
											</a>
										) : (
											<div className="footer__link__loading email  skeleton" />
										)}
									</li>
									<li>
										{options ? (
											<address
												className="footer__link"
												dangerouslySetInnerHTML={{ __html: options.address }}
											/>
										) : (
											<div className="footer__link__loading address skeleton" />
										)}
									</li>
								</ul>
							</div>
							<div className="footer__col__terabit footer__col">
								<Terabit />
								<div>
									<p className={'footer__licence'}>
										HOS Events LLC (DET Licence #1202416)
									</p>
									<p className={'footer__licence'}>
										HOS Practices LLC (DET Licence #1250911)
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		);
	},
);
