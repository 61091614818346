import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Navigate,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import { RoutsPath } from 'src/@types';
import { PageLayout } from '../../components/layout/page.layout';
import { Banner } from 'src/components/banner/banner.component';
import { SectionGoTeam } from 'src/components/sections/section-go-team/section-go-team.component';
import { SectionMasters } from 'src/components/sections/section-masters/section-masters.component';
import { SectionPosts } from 'src/components/sections/section-posts/section-posts.component';
import { SliderSectionImg } from 'src/components/slide-section-img/slide-section-img.component';
import { SliderCardInfo } from 'src/components/slider-card-info/slider-card-info.component';
import { SliderEffectCoverflow } from 'src/components/slider-effect-coverflow/slider-effect-coverflow.component';
import { SliderSticky } from 'src/components/slider-sticky/slider-ellipse.component';
import { Button } from 'src/components/ui/buttons/button/button.component';
import { TextBlock } from 'src/components/ui/text-block/text-block.components';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { apiServices } from 'src/services/api';
import { createLangHref } from 'src/utils/href.utils';
import cl from './index.module.scss';
import { usePracticeMock } from './mock/data.mock';

const useGenreMasters = (slug: string) => {
	return useQuery({
		queryKey: [slug, 'genre/master'],
		queryFn: async () => {
			return await apiServices.getGenreMasters(slug);
		},
	});
};

const PracticeSlugPage = () => {
	const { slug } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const { t, i18n } = useTranslation();
	const refInit = useRef(false);
	const data = usePracticeMock();
	const practice = data[slug as string];
	const { data: genre, isLoading } = useGenreMasters(practice.genre_slug);
	const refTimerId = useRef<NodeJS.Timeout | null>(null);
	const navigation = useNavigate();
	const { width = window.innerWidth } = useWindowSize();

	const isEllipse = useMemo(() => {
		return width > 996;
	}, [width]);

	const singup = () => {
		searchParams.set('practice', String(slug));
		navigation({
			pathname: createLangHref(i18n.language, `${RoutsPath.schedule}`),
			search: searchParams.toString(),
		});
	};

	useEffect(() => {
		if (refInit.current) {
			refTimerId.current = setTimeout(() => {
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			}, 600);
		} else {
			refInit.current = true;
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	}, [slug]);

	if (slug === undefined || data[slug] === undefined) {
		return <Navigate to={RoutsPath.not_found_base} />;
	}

	return (
		<PageLayout positionHeader="fixed">
			<div className={cl.page}>
				<div className="container">
					<Banner
						{...practice.banner}
						actions={
							<Button
								theme="rainbow"
								radius={16}
								onClick={singup}
								className={cl.banner__btn}
							>
								{t('btn.book') + '!'}
							</Button>
						}
					/>
					<TextBlock {...practice.info} />
				</div>
				{slug === 'guided-meditations-demo' && (
					<>
						<SliderSectionImg posts={practice.posts.items} />
						<SliderSectionImg
							posts={practice.posts.items}
							autoplay={{ play: true, duration: 3000 }}
						/>
					</>
				)}

				<div className={cl.section__start}>
					<div className={cl.section__start__content}>
						<h3 className={cl.title}>{practice.start.title}</h3>
						<SliderCardInfo
							items={practice.start.items}
							classes={{ slide: cl.slide__start }}
						/>
					</div>
				</div>
				<div className={cl.section__who__content}>
					<SectionPosts
						title={practice.posts.title}
						description={practice.posts.description}
						posts={practice.posts.items}
						reverse={data[slug].reverse}
					/>
				</div>
				{practice.gallery.items.length > 0 && (
					<div className={cl.gallery}>
						<h2 className={cl.title}>{practice.gallery.title}</h2>
						{isEllipse ? (
							<SliderSticky
								slides={practice.gallery.items.map((item) => item.img)}
							/>
						) : (
							<SliderEffectCoverflow slides={practice.gallery.items} />
						)}
					</div>
				)}

				{!isLoading && genre && genre.genremaster_set.length > 0 && (
					<div className={cl.masters} key="genre">
						<SectionMasters
							header={
								<h3 className={cl.title + ' ' + cl.pd77}>
									{t('section.masters.title')}
								</h3>
							}
							masters={
								genre?.genremaster_set.map((master) => master.master) || []
							}
							loading={false}
							transparent
						/>
					</div>
				)}

				{isLoading && (
					<div className={cl.masters} key="loading_genre">
						<SectionMasters
							header={
								<h3 className={cl.title + ' ' + cl.pd77}>
									{t('section.masters.title')}
								</h3>
							}
							masters={[]}
							loading={true}
							transparent
						/>
					</div>
				)}

				<SectionGoTeam />
			</div>
		</PageLayout>
	);
};

export default PracticeSlugPage;
