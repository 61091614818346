import ms1 from '../../../assets/img/ms-1.png';
import ms1w from '../../../assets/img/ms-1.webp';
import ms2 from '../../../assets/img/ms-2.png';
import ms2w from '../../../assets/img/ms-2.webp';
import ms3 from '../../../assets/img/ms-3.png';
import ms3w from '../../../assets/img/ms-3.webp';
import ms4 from '../../../assets/img/ms-4.png';
import ms4w from '../../../assets/img/ms-4.webp';

export const MISSION_MOCK: {
	preview: { type: 'jpg' | 'png' | 'webp'; uri: string }[];
	title: { [key: string]: string };
	subtitle: { [key: string]: string };
}[] = [
	{
		title: {
			ru: 'INTO 5',
			en: 'INTO 5',
		},
		subtitle: {
			ru: 'Практики для единения с собой, успокоения ума и снятия напряжения в теле',
			en: 'Practices to connect with yourself, calm the mind and relieve tension from the body',
		},
		preview: [
			{
				type: 'webp',
				uri: ms1w,
			},
			{
				type: 'jpg',
				uri: ms1,
			},
		],
	},
	{
		title: {
			ru: 'Индивидуальные практики',
			en: 'Individual practices',
		},
		subtitle: {
			ru: 'чтобы погрузиться глубже и насладиться',
			en: 'to dive deeper and enjoy an individual program with guidance from a curator',
		},
		preview: [
			{
				type: 'webp',
				uri: ms2w,
			},
			{
				type: 'jpg',
				uri: ms2,
			},
		],
	},
	{
		title: {
			ru: 'Корпоративные ретриты',
			en: 'Thematic events',
		},
		subtitle: {
			ru: 'для вашего бизнеса, друзей и семьи',
			en: 'for your business, friends and family',
		},
		preview: [
			{
				type: 'webp',
				uri: ms3w,
			},
			{
				type: 'jpg',
				uri: ms3,
			},
		],
	},
	{
		title: {
			ru: 'Ретриты',
			en: 'Retreats',
		},
		subtitle: {
			ru: 'Побег из шумного города на один день, чтобы выдохнуть и перезарядиться.',
			en: 'A one-day escape to leave the city behind for a while.',
		},
		preview: [
			{
				type: 'webp',
				uri: ms4w,
			},
			{
				type: 'jpg',
				uri: ms4,
			},
		],
	},
];
