import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AsideNavigation } from '../../components/aside/aside-profile/aside-profile.component';
import FormEditProfilePassword from '../../components/forms/form-edit-profile-password/form-edit-profile-password.component';
import FormEditProfile from '../../components/forms/form-edit-profile/form-edit-profile.component';
import ContentLayout from '../../components/layout/content-layout/content-layout.component';
import { PageLayout } from '../../components/layout/page.layout';
import { Breadcrumbs } from '../../components/ui/breadcrumbs/breadcrumbs.component';
import { ButtonGroup } from '../../components/ui/button-group/button-group.component';
import cl from './index.module.scss';

type Form = 'info' | 'password';

const FORMS = {
	info: <FormEditProfile key="FormEditProfile" />,
	password: <FormEditProfilePassword key="FormEditProfilePassword" />,
};

const ProfilePage = () => {
	const [form, setForm] = useState<'info' | 'password'>('info');
	const { t } = useTranslation();
	const onChangeForm = (e: React.MouseEvent<HTMLButtonElement>) => {
		const target = e.currentTarget;
		const data = target.dataset.value || 'info';
		setForm(data as Form);
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<PageLayout positionHeader="fixed">
			<div className={cl.page}>
				<div className={'header-offset'} />
				<div className="container">
					<div className={'box20'}></div>
					<Breadcrumbs items={[{ name: t('page.profile'), translate: true }]} />
					<h1 className={'title-section-small padding-bottom_40'}>
						{t('page.profile')}
					</h1>
					<ContentLayout aside={<AsideNavigation />} small mobileTabs>
						<>
							<div className={cl.tab}>
								<ButtonGroup
									active={form}
									classes={{
										button: cl.tab__item,
										root: cl.tab__item,
									}}
									buttons={[
										{
											label: t('tab.label.data'),
											onClick: onChangeForm,
											data: 'info',
										},
										{
											label: t('tab.label.security'),
											onClick: onChangeForm,
											data: 'password',
										},
									]}
								/>
							</div>
							<div className={cl.paper + ' ' + cl.form}>{FORMS[form]}</div>
						</>
					</ContentLayout>
				</div>
			</div>
		</PageLayout>
	);
};

export default ProfilePage;
