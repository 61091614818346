import { createContext, useState } from 'react';

export type Form =
	| 'login'
	| 'registration'
	| 'reset_password'
	| 'code'
	| 'email'
	| 'verify';

export const FormAuthContext = createContext<{
	form: Form;
	changeAuthForm: (form: Form) => void;
	payload?: { [key: string]: unknown };
	changePayload: (form: undefined | { [key: string]: unknown }) => void;
}>({
	form: 'login',
	changeAuthForm: (value: Form) => void 0,
	payload: undefined,
	changePayload: (data) => void 0,
});

const FormAuthContextProvider = ({
	children,
}: {
	children: JSX.Element[] | JSX.Element;
}) => {
	const [form, setForm] = useState<Form>('login');
	const [payload, setPayload] = useState<
		undefined | { [key: string]: unknown }
	>(undefined);
	const contextValue = {
		form,
		changeAuthForm: setForm,
		payload,
		changePayload: setPayload,
	};
	return (
		<FormAuthContext.Provider value={contextValue}>
			{children}
		</FormAuthContext.Provider>
	);
};

export default FormAuthContextProvider;
