import { Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../ui/buttons/button/button.component';
import { CloseEyeIcon, OpenEyeIcon } from '../../ui/icons';
import { apiServices } from '../../../services/api';
import cl from './form-edit-profile-password.module.scss';
import { InputField } from '../../ui/input';
import {
	ProfileEditPasswordFormData,
	profileEditPasswordFormSchema,
} from './form-edit-profile-password.schema';

const FormEditProfilePassword = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
	const [showPasswordOld, setShowPasswordOld] = useState(false);
	const [, setLoading] = useState(false);
	const [message, setMessage] = useState<null | {
		status: 'error' | 'success';
		text: string;
	}>(null);
	const controller = new AbortController();
	const { t } = useTranslation();

	const onSubmit = async (
		values: ProfileEditPasswordFormData,
		actions: FormikHelpers<ProfileEditPasswordFormData>,
	) => {
		setLoading(true);
		setMessage(null);
		apiServices
			.changeAuthPassword(
				{
					password_1: values.password,
					password_2: values.password_confirm,
					password_old: values.password_old,
				},
				controller.signal,
			)
			.then(() => {
				actions.setSubmitting(false);
				actions.resetForm();
				setMessage({ status: 'success', text: t('password.change.success') });
			})
			.catch((e) => {
				setMessage({ status: 'error', text: e.msg });
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		return () => {
			controller.abort();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={cl.form__wrap} key="form-edit-profile-password">
			<Formik
				onSubmit={onSubmit}
				initialValues={{
					password: '',
					password_confirm: '',
					password_old: '',
				}}
				validationSchema={profileEditPasswordFormSchema}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					setSubmitting,
					isSubmitting,
				}) => (
					<form onSubmit={handleSubmit} autoComplete="off" className={cl.form}>
						<h4 className={cl.form__title}>
							{t('form.profile.password.title')}
						</h4>
						<InputField
							label={t('form.label.password.old')}
							name="password_old"
							error={
								touched.password_old && errors.password_old
									? t(errors.password_old)
									: undefined
							}
							value={values.password_old}
							onChange={handleChange}
							onBlur={handleBlur}
							onFocus={() => setSubmitting(false)}
							id={'edit-profile-password-id-name'}
							type={showPasswordOld ? 'text' : 'password'}
							endAdornment={
								<button
									type="button"
									className={cl.form__eye}
									onClick={() => setShowPasswordOld((prev) => !prev)}
								>
									{showPasswordOld ? <CloseEyeIcon /> : <OpenEyeIcon />}
								</button>
							}
							classes={{
								input: cl.input,
							}}
						/>

						<InputField
							label={t('form.label.password.new')}
							name="password"
							error={
								touched.password && errors.password
									? t(errors.password)
									: undefined
							}
							value={values.password}
							onChange={handleChange}
							onBlur={handleBlur}
							onFocus={() => setSubmitting(false)}
							id={'edit-profile-password-id-name'}
							type={showPassword ? 'text' : 'password'}
							endAdornment={
								<button
									type="button"
									className={cl.form__eye}
									onClick={() => setShowPassword((prev) => !prev)}
								>
									{showPassword ? <CloseEyeIcon /> : <OpenEyeIcon />}
								</button>
							}
							classes={{
								input: cl.input,
							}}
						/>

						<InputField
							label={t('form.label.password.confirm')}
							name="password_confirm"
							error={
								touched.password_confirm && errors.password_confirm
									? t(errors.password_confirm)
									: undefined
							}
							value={values.password_confirm}
							onChange={handleChange}
							onBlur={handleBlur}
							onFocus={() => setSubmitting(false)}
							id={'edit-profile-password-id-name'}
							type={showPasswordConfirm ? 'text' : 'password'}
							endAdornment={
								<button
									type="button"
									className={cl.form__eye}
									onClick={() => setShowPasswordConfirm((prev) => !prev)}
								>
									{showPasswordConfirm ? <CloseEyeIcon /> : <OpenEyeIcon />}
								</button>
							}
							classes={{
								input: cl.input,
							}}
						/>

						<Button
							type="submit"
							className={cl.form__submit}
							disabled={
								isSubmitting ||
								!!(errors.password_old && touched.password_old) ||
								!!(errors.password && touched.password) ||
								!!(errors.password_confirm && touched.password_confirm)
							}
						>
							{t('form.btn.change')}
						</Button>
					</form>
				)}
			</Formik>

			{message && (
				<div className={cl.message + ' ' + cl[message.status]}>
					{message.text}
				</div>
			)}
		</div>
	);
};

export default FormEditProfilePassword;
