import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Preview, RoutsPath } from 'src/@types';
import { createLangHref } from 'src/utils/href.utils';
import cl from './card-practice.module.scss';

export const CardPractice = ({
	title,
	preview,
	slug,
	shift = false,
}: {
	preview: Preview;
	title: string;
	slug: string;
	shift?: boolean;
}) => {
	const { i18n } = useTranslation();
	const navigation = useNavigate();
	const href = createLangHref(i18n.language, RoutsPath.practice + '/' + slug);
	const goto = () => navigation(href);

	return (
		<article className={cl.card__post} onClick={goto}>
			<picture className={cl.card__post__image}>
				{preview.map((img) => (
					<source
						type={'image/' + img.type}
						srcSet={img.uri}
						key={img.type}
						media={img.media}
					/>
				))}
				<img
					src={preview[preview.length - 1].uri}
					className={cl.card__post__image}
					alt=""
					loading="lazy"
					decoding="async"
				/>
			</picture>

			<div className={cl.card__post__info}>
				<p className={shift === true ? cl.break : ''}>{title}</p>
			</div>
		</article>
	);
};
