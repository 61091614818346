import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';
import { PageLayout } from '../../components/layout/page.layout';
import { SectionAnimation3d } from '../../components/sections/section-animation-3d/section-animation-3d.component';
import { SectionGoTeam } from '../../components/sections/section-go-team/section-go-team.component';
import { SectionMasters } from '../../components/sections/section-masters/section-masters.component';
import { SectionMission } from '../../components/sections/section-mission/section-mission.component';
import { SectionOurMeditationContainer } from '../../components/sections/section-our-meditation/section-our-meditation.component';
import { SectionMissionGsap } from 'src/components/sections/section-mission-gsap/section-mission.component';
import { HeaderSection } from 'src/components/ui/header-section/header-section.component';
import { useApplication } from 'src/application/application.context';
import { useWindowSize } from 'src/hooks/useWindowSize';
import cl from './index.module.scss';

const HomePage = () => {
	const { width = window.innerWidth } = useWindowSize();
	const { hash } = useLocation();
	const refMount = useRef<boolean>(false);
	const refTimerId = useRef<NodeJS.Timeout | null>(null);
	const refMission = useRef<HTMLDivElement | null>(null);
	const refVideo = useRef<null | HTMLVideoElement>(null);
	const { t } = useTranslation();
	const { store_masters } = useApplication();

	const masters = useMemo(() => {
		return store_masters.state.isLoading ||
			store_masters.state.masters === null ||
			store_masters.state.masters === undefined
			? []
			: store_masters.state.masters.filter(
					(item) => item.show_on_main === true,
				);
	}, [store_masters.state.masters, store_masters.state.isLoading]);

	const { ref, inView } = useInView({
		threshold: 0,
	});
	const isPlay = useRef(false);

	useEffect(() => {
		refVideo.current && refVideo.current.pause();
	}, []);

	useEffect(() => {
		if (refVideo.current) {
			if (inView && isPlay.current === false) {
				refVideo.current.play();
				isPlay.current = true;
			} else if (!inView && isPlay.current) {
				refVideo.current.pause();
				isPlay.current = false;
			}
		}
	}, [inView]);

	useEffect(() => {
		refMount.current = true;
		if (hash !== '#about' && hash !== '#contacts' && hash === '') {
			window.scrollTo({
				top: 0,
			});
		}
		return () => {
			refTimerId.current && clearTimeout(refTimerId.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		refTimerId.current = setTimeout(() => {
			if (refMission && refMission.current && hash === '#about') {
				refMission.current.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'center',
				});
			}
		}, 300);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hash, refMount.current]);

	return (
		<PageLayout positionHeader="fixed">
			<>
				<SectionAnimation3d />
				<SectionOurMeditationContainer />

				<div className={cl.box}></div>
				{width > 1280 ? (
					<SectionMissionGsap ref={refMission} />
				) : (
					<SectionMission ref={refMission} />
				)}

				<div className={cl.video__container} ref={ref}>
					{/* eslint-disable react/no-unknown-property */}
					<video
						ref={refVideo}
						className={cl.video}
						muted={true}
						loop={true}
						autoPlay={false}
						preload="metadata"
						playsInline={true}
						webkit-playsinline="true"
					>
						<source src={`/video/home.webm`} type="video/webm" />
					</video>
				</div>

				<SectionMasters
					header={
						<HeaderSection
							title={t('master.title')}
							description={t('master.desc')}
						/>
					}
					masters={masters}
					loading={store_masters.state.isLoading}
				/>
				<SectionGoTeam />
			</>
		</PageLayout>
	);
};

export default HomePage;
